import { motion } from "framer-motion";
import { useEffect } from "react";

function ContactForm(props) {
  const main = {
    visible: {
      opacity: 1,
      y: -0,
    },
    hidden: { opacity: 0, y: -50 },
  };
  const child = {
    visible: {
      opacity: 1,
      x: -0,
      transition: {
        duration: 0.3,
        type: "keyframes",
        ease: "easeInOut"
      },
    },
    hidden: { opacity: 0, x: -25 },
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  function hideContactForm() {
    props.setIsContactFormVisible(false);
    document.body.style.overflowY = "auto"
  }

  return (
    <motion.div
      style={{ top: window.scrollY + "px" }}
      initial="hidden"
      animate={{ opacity: [0, 1] }}
      transition={{ duration: 0.3 }}
      onClick={hideContactForm}
      className="contact-form-items--container"
    >
      <motion.form
        initial="hidden"
        animate="visible"
        onClick={(e) => e.stopPropagation()}
        action="https://formsubmit.co/Msalman.00777@gmail.com" method="POST"
        transition={{
          duration: 0.5,
          type: "keyframes",
          ease: "easeInOut",
          staggerChildren: 0.11,
          delayChildren: 0.3
        }}
        variants={main}
        className="contact-inputs"
      >
        <motion.div className="text-row">
          <motion.h3 variants={child} >Have a Question?</motion.h3>
          <motion.span variants={child}>Get in touch now.</motion.span>
        </motion.div>
        <motion.div className="input-col">
          <motion.div variants={child} className="input--container">
            <input type="text" name="first name" required />
            <label className="input--placeholder">First Name</label>
          </motion.div>
          <motion.div variants={child} className="input--container">
            <input type="text" name="last name" required />
            <label className="input--placeholder">Last Name</label>
          </motion.div>
        </motion.div>
        <motion.div variants={child} className="input--container">
          <input type="text" name="email" required />
          <label className="input--placeholder">Email</label>
        </motion.div>

        <motion.div variants={child} className="input--container">
          <textarea
            required
            name="message"
            cols="auto"
            rows="5"
          ></textarea>
          <label className="input--placeholder">Your Message</label>
        </motion.div>
        <motion.button variants={child}>Submit</motion.button>
      </motion.form>
    </motion.div>
  );
}

export default ContactForm;
