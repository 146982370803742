function Testimonial(props) {
  return (
    <div
      className="testimonial testimonial-1"
    >
      {/* <div className="profile-picture">
        <img src={props.clientPfp} alt={props.clientName} />
      </div> */}
      <div className="customer--name">
        <h3>{props.clientName}</h3>
      </div>
      <div className="text">
        <p>"{props.description}"</p>
      </div>
    </div>
  )
}

export default Testimonial;