import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
function ProjectsPython() {
  const animation = useAnimation();

  const image = {
    visible: {
      opacity: 1,
      x: -0,
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeInOut",
        staggerChildren: 0.4,
      },
    },
    hidden: { opacity: 0, x: -25 },
  };

  const text = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "backInOut",
      },
    },
    hidden: { opacity: 0, y: 25 },
  };

  const [ref, InView] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (InView) {
      animation.start("visible");
    }
  }, [animation, InView]);

  return (
    <motion.div className="projects--container">
      <motion.h1 variants={text}>Python and Machine learning Project</motion.h1>
      <motion.div className="projects">
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref}
          variants={image}
          initial="hidden"
          animate={animation}
          className="project-3 project"
        >
          <motion.div variants={image} className="project--image--container">
          <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
            <SwiperSlide>
            <a
              href="#t"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/scan1.jpeg" alt="object detection app" />
            </a>
            </SwiperSlide>
            <SwiperSlide>
            <a
              href="#t"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/scan2.jpeg" alt="object detection app" />
            </a>
            </SwiperSlide>
            <SwiperSlide>
            <a
              href="#t"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/scan3.jpeg" alt="object detection app" />
            </a>
            </SwiperSlide>
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="#t"
                target="_blank"
                rel="noopener noreferrer"
              >
                Object Detection
              </a>
              <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">Python</div>
                  <div className="technology">YOLO V3</div>
                  <div className="technology">OpenCV</div>
                  <div className="technology">NumPy</div>
                  <div className="technology">Darknet libraries</div>
                </div>
              </div>
              <p>
                I created object Detection Using Yolo V3. I used Python Language
                with OpenCV, NumPy, Darknet libraries
              </p>
              {/* <div className="buttons">
                  <button>
                    <a href="https://pokerpie.io/">
                      View Github
                    </a>
                  </button>
                </div> */}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ProjectsPython;
