import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

function Education() {
  const animation = useAnimation();
  const boxVariant = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: { opacity: 0, y: 50 },
  };

  const [ref, inView] = useInView({ threshold: 0.3 });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [animation, inView]);

  return (
    <motion.div
      variants={boxVariant}
      initial="hidden"
      id="Education"
      transition={{ duration: 1, ease: "easeInOut", staggerChildren: 0.3 }}
      animate={animation}
      className="services--container"
    >
      <h1>Education </h1>
      <div className="services">
        <motion.div
          ref={ref}
          transition={{
            duration: 1,
            type: "spring",
            stiffness: "70",
            ease: "easeInOut",
            staggerChildren: 0.3,
          }}
          variants={boxVariant}
          className="service-1 service"
        >
          <h2 className="service--title">MSC Computing and information technology</h2>
          <h3 className="service--name">
          University of Greenwich London - 1/2023 – 01/2024
          </h3>
          <div className="text">
            
          </div>
        </motion.div>
        <motion.div
          ref={ref}
          transition={{
            duration: 1,
            type: "spring",
            stiffness: "70",
            ease: "easeInOut",
            staggerChildren: 0.3,
          }}
          variants={boxVariant}
          className="service-1 service"
        >
          <h2 className="service--title">BSc Software Engineering</h2>
          <h3 className="service--name">University of South Asia - 09/2017 – 06/2021 </h3>
          <div className="text">
            <p>CPGA 3.52</p>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Education;
