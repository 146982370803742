import Testimonial from "./Testimonial";
import Slider from "react-slick";

function Testimonials() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    nextArrow: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="slick-next"
        viewBox="0 0 16 16"
      >
        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
      </svg>
    ),
    prevArrow: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="slick-prev"
        viewBox="0 0 16 16"
      >
        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
      </svg>
    ),
    pauseOnHover: true,
  };

  return (
    <div className="client--testimonials--container" id="Testimonials">
      <h1>What my clients have to say.</h1>
      <div className="client--testimonials">
        <Slider {...settings}>
          <Testimonial
            clientName="Client"
            description='"I really like this seller, he was always responsive for what i wanted. I recommend him for any project you wish to take him. Super professional. Thanks!"'
          />
          <Testimonial
            clientName="Client"
            description='"Very helpful and open to feedback. Worked on code related to unity Game Developed (C#)"'
          />
          <Testimonial
            clientName="Client"
            description='"He is extremely patient, helpful and goes the extra mile to ensure customer satisfaction. Really enjoyed
working with him and learning the pieces involved in creating and building assests"'
          />
        </Slider>
      </div>

      {/* <motion.div
      variants={boxVariant}
      initial="hidden"
      animate={animation}
      transition={{ duration: 1, ease: "easeInOut" }}
      className="client--testimonials--container"
    >
      <h1>What my clients have to say.</h1>
      <motion.div
        variants={boxVariant}
        initial="hidden"
        animate={animation}
        transition={{ duration: 1, ease: "easeInOut", staggerChildren: 0.3 }}
        className="client--testimonials"
      >
        <Testimonial clientPfp="./images/stefanlines-pfp.svg" clientName="stefanlines" description="a great person to work with, very helpful with answering my questions. very knowlegable and experienced." reference={ref} boxVariant={boxVariant} />
        <Testimonial clientPfp="./images/toniobject-pfp.webp" clientName="Toniobject" description="He did a great job on implementing the theme. He is a patient and skilled developer." reference={ref} boxVariant={boxVariant} />
        <Testimonial clientPfp="./images/henrydozie-pfp.svg" clientName="Henrydozie" description="He did a very good job in so little time. He has a clear understanding of what he does and he communicates really well. He was patient, kind, and easy to talk to." reference={ref} boxVariant={boxVariant} />
      </motion.div>
    </motion.div> */}
    </div>
  );
}

export default Testimonials;
