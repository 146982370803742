import "./index.css";
import Profile from "./components/Profile/Profile";
import Services from "./components/services/Services";
import Testimonials from "./components/testimonials/Testimonials";
import BoxContent from "./components/BoxContent";
import ProjectsUnity from "./components/Projects/ProjectsUnity";
import ProjectsPython from "./components/Projects/ProjectsPython";
import ProjectsAndroid from "./components/Projects/ProjectsAndroid";
import ProjectsWeb from './components/Projects/ProjectsWeb';
import Header from './components/Header/Header';
import Education from "./components/Education/education";
import Achievements from "./components/Projects/Achievements";
function App() {


  return (
    <div className="App" >
    <Header />
      <Profile />
      <Education />
      <Services />
      <BoxContent>
        <Testimonials />
        <ProjectsUnity />
        <ProjectsWeb />
        <ProjectsAndroid />
        <ProjectsPython />
        <Achievements />
      </BoxContent>
    </div>
  );
}

export default App;
