import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

function ProjectsWeb() {
  const animation = useAnimation();
  const animation2 = useAnimation();

  const image = {
    visible: {
      opacity: 1,
      x: -0,
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeInOut",
        staggerChildren: 0.4,
      },
    },
    hidden: { opacity: 0, x: -25 },
  };

  const text = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "backInOut",
      },
    },
    hidden: { opacity: 0, y: 25 },
  };

  const [ref, InView] = useInView({ threshold: 0.3 });
  const [ref2, InView2] = useInView({ threshold: 0.3 });

  useEffect(() => {
    if (InView) {
      animation.start("visible");
    }
    if (InView2) {
      animation2.start("visible");
    }
  }, [
    animation,
    InView,
    animation2,
    InView2,
  ]);

  return (
    <motion.div className="projects--container">
      <motion.h1 variants={text}>Web Development Projects</motion.h1>
      <motion.div className="projects">
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref}
          variants={image}
          initial="hidden"
          animate={animation2}
          className="project-3 project project--reverse"
        >
          <motion.div variants={image} className="project--image--container">
          <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
            <SwiperSlide>
            <a
              href="#b"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/gallery.jpg" alt="gallery" />
            </a>
            </SwiperSlide>
            {/* <SwiperSlide>
            <a
              href="#b"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/gallery.jpg" alt="gallery" />
            </a>
            </SwiperSlide> */}
            </Swiper>
            
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="#b"
                target="_blank"
                rel="noopener noreferrer"
              >
                Greenwich Landmarks Picture Selling Website
              </a>
              <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">HTML</div>
                  <div className="technology">CSS</div>
                  <div className="technology">Javascript</div>
                  <div className="technology">Ecommerce Functionality</div>
                </div>
              </div>
              <p>
              I created a Responsive Website to fulfill my web engineering module requirement It’s a complete e commerce website where user can buy pictures of Greenwich landmarks
              </p>
              {/* <div className="buttons">
                  <button>
                    <a href="https://pokerpie.io/">
                      View Github
                    </a>
                  </button>
                </div> */}
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref2}
          variants={image}
          initial="hidden"
          animate={animation2}
          className="project-3 project"
        >
          <motion.div variants={image} className="project--image--container">
          <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
            <SwiperSlide>
            <a
              href="https://github.com/developermr/librarymanagmentsysteminphp"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/library.jpg" alt="library" />
            </a>
            </SwiperSlide>
            {/* <SwiperSlide>
            <a
              href="https://github.com/developermr/librarymanagmentsysteminphp"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/library.jpg" alt="library" />
            </a>
            </SwiperSlide> */}
            </Swiper>
            
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="https://github.com/developermr/librarymanagmentsysteminphp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Library Management System
              </a>
              <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">HTML</div>
                  <div className="technology">CSS</div>
                  <div className="technology">Javascript</div>
                  <div className="technology">PHP</div>
                  <div className="technology">Ecommerce Functionality</div>
                </div>
              </div>
              <p>
              I created a Responsive Website to fulfill my web engineering module requirement It’s a complete e commerce website where user can buy pictures of Greenwich landmarks
              </p>
              <div className="buttons">
                  <button>
                    <a href="https://github.com/developermr/librarymanagmentsysteminphp">
                      View Github
                    </a>
                  </button>
                </div>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ProjectsWeb;
