import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

function Services() {
  const animation = useAnimation();
  const boxVariant = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: { opacity: 0, y: 50 },
  };

  const [ref, inView] = useInView({ threshold: 0.3 });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [animation, inView]);

  return (
    <motion.div
      variants={boxVariant}
      initial="hidden"
      id="Experience"
      transition={{ duration: 1, ease: "easeInOut", staggerChildren: 0.3 }}
      animate={animation}
      className="services--container"
    >
      <h1>Professional Experience </h1>
      <div className="services">
        <motion.div
          ref={ref}
          transition={{
            duration: 1,
            type: "spring",
            stiffness: "70",
            ease: "easeInOut",
            staggerChildren: 0.3,
          }}
          variants={boxVariant}
          className="service-1 service"
        >
          <h2 className="service--title">The Game Storm Studios</h2>
          <h3 className="service--name">
            Software Engineer - 07/2021 – 11/2022
          </h3>
          <div className="text">
            <ul>
              <li>
                {" "}
                Contributed to the development of a mobile games using Unity.
              </li>
              <li>
                Used Git Version Control for project Backup. Used Trello for
                Projects Progression.
              </li>
              <li>
                {" "}
                Optimizing game performance through efficient coding, asset
                optimization, and Unity profiling tools.
              </li>
              <li> Check Logcat as a Unit Testing of game.</li>
              <li>
                {" "}
                Create Games from Scratch for different Platforms (Android, IOS,
                PC)
              </li>
            </ul>
          </div>
        </motion.div>
        <motion.div
          ref={ref}
          transition={{
            duration: 1,
            type: "spring",
            stiffness: "70",
            ease: "easeInOut",
            staggerChildren: 0.3,
          }}
          variants={boxVariant}
          className="service-1 service"
        >
          <h2 className="service--title">Enigma Apps Intern</h2>
          <h3 className="service--name">Web Developer - 04/20219 – 09/2019 </h3>
          <div className="text">
            <ul>
              <li>
                Create Website susing HTML, CSS, PHP, JavaScript
              </li>
              <li>
              Server end Deployment(Namecheap, GoDaddy)
              </li>
            </ul>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Services;
