import { useState } from "react";

function Header() {
  const [dropdown, setDropdown] = useState(false);

  function dropdownHandler() {
    setDropdown(!dropdown);
  }

  return (
    <header>
      {!dropdown ? (
        <img
          className="hamburger-icon"
          onClick={dropdownHandler}
          src="./images/menu.svg"
          alt="hamburger"
        />
      ) : (
        <img
          className="hamburger-icon"
          onClick={dropdownHandler}
          src="./images/close.svg"
          alt="hamburger"
        />
      )}
      <ul className={`${dropdown ? "dropdown-items" : ""}`}>
        <a href="#Experience">
          <li className="list--services">Experience</li>
        </a>
        <a href="#Testimonials">
          <li className="list--client-testimonials">Testimonials</li>
        </a>
        <a href="#Projects">
        <li className="list--projects">Projects</li>
        </a>
        <a href="#Education">
        <li className="list--contact-me">Education</li>
        </a>
        <a href="#AboutMe">
        <li className="list--about-me">About Me</li>
        </a>
        
      </ul>
    </header>
  );
}

export default Header;
