import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
function ProjectsAndroid() {
  const animation = useAnimation();

  const image = {
    visible: {
      opacity: 1,
      x: -0,
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeInOut",
        staggerChildren: 0.4,
      },
    },
    hidden: { opacity: 0, x: -25 },
  };

  const text = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "backInOut",
      },
    },
    hidden: { opacity: 0, y: 25 },
  };

  const [ref, InView] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (InView) {
      animation.start("visible");
    }
  }, [animation, InView]);

  return (
    <motion.div className="projects--container">
      <motion.h1 variants={text}>Android Projects</motion.h1>
      <motion.div className="projects">
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref}
          variants={image}
          initial="hidden"
          animate={animation}
          className="project-3 project yoga-project"
        >
          <motion.div variants={image} className="project--image--container">
          <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
            <SwiperSlide>
            <a
              href="#d"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/yoga.jpeg" alt="Accountant" />
            </a>
            </SwiperSlide>
            <SwiperSlide>
            <a
              href="#d"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/yoga2.jpeg" alt="Accountant" />
            </a>
            </SwiperSlide>
            <SwiperSlide>
            <a
              href="#d"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/yoga3.jpeg" alt="Accountant" />
            </a>
            </SwiperSlide>
            <SwiperSlide>
            <a
              href="#d"
              className="container"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/yoga4.jpeg" alt="Accountant" />
            </a>
            </SwiperSlide>
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="https://paku.pe"
                target="_blank"
                rel="noopener noreferrer"
              >
                Yoga Android App
              </a>
              <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">Android App</div>
                  <div className="technology">Firebase Database</div>
                  <div className="technology">SQLite</div>
                  <div className="technology">Cloud Communication</div>
                </div>
              </div>
              <p>
              I created this app to fulfill my Mobile App development Module in University of the Greenwich. I learned Firebase database, API Integration, Authentication system and App Debugging from this project. 
              </p>
              {/* <div className="buttons">
                  <button>
                    <a href="">
                      View Github
                    </a>
                  </button>
                </div> */}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ProjectsAndroid;
