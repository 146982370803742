import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
function ProjectsUnity() {
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const animation5 = useAnimation();

  const image = {
    visible: {
      opacity: 1,
      x: -0,
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeInOut",
        staggerChildren: 0.4,
      },
    },
    hidden: { opacity: 0, x: -25 },
  };

  const text = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "backInOut",
      },
    },
    hidden: { opacity: 0, y: 25 },
  };

  const [ref, InView] = useInView({ threshold: 0.6 });
  const [ref2, InView2] = useInView({ threshold: 0.6 });
  const [ref3, InView3] = useInView({ threshold: 0.6 });
  const [ref4, InView4] = useInView({ threshold: 0.6 });
  const [ref5, InView5] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (InView) {
      animation.start("visible");
    }
    if (InView2) {
      animation2.start("visible");
    }
    if (InView3) {
      animation3.start("visible");
    }
    if (InView4) {
      animation4.start("visible");
    }
    if (InView5) {
      animation5.start("visible");
    }
  }, [
    animation,
    InView,
    animation2,
    InView2,
    animation3,
    InView3,
    animation4,
    InView4,
    animation5,
    InView5,
  ]);

  return (
    <motion.div className="projects--container" id="Projects">
      <motion.h1 variants={text}>Game Development Projects</motion.h1>
      <motion.div className="projects">
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref}
          variants={image}
          initial="hidden"
          animate={animation}
          className="project-3 project"
        >
          <motion.div variants={image} className="project--image--container">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/trafficgame5.jpeg" alt="trafficgame" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/trafficgame2.jpeg" alt="trafficgame" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/trafficgame3.jpeg" alt="trafficgame" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/trafficgame4.jpeg" alt="trafficgame" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/trafficgame.jpg" alt="trafficgame" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/trafficgame6.jpeg" alt="trafficgame" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/trafficgame7.jpeg" alt="trafficgame" />
                </a>
              </SwiperSlide>
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule"
                target="_blank"
                rel="noopener noreferrer"
              >
                UK Traffic Rules Car Simulator Mobile Game
              </a>
              {/* <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">HTML</div>
                  <div className="technology">CSS</div>
                  <div className="technology">Nextjs</div>
                  <div className="technology">Firebase Database</div>
                </div>
              </div> */}
              <p>
                I created this game to fulfill my MSc project from university of
                Greenwich The purpose of this game is to provide awareness about
                uk traffic rules for newly drivers
              </p>
              <div className="buttons">
                <button>
                  <a href="https://play.google.com/store/apps/details?id=com.Salman.uk.drivingrule">
                    View Game
                  </a>
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref2}
          variants={image}
          initial="hidden"
          animate={animation2}
          className="project-3 project project--reverse"
        >
          <motion.div variants={image} className="project--image--container">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
              <SwiperSlide>
                <a
                  href="#a"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/driving2.png" alt="" />
                </a>
              </SwiperSlide>
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="#u"
                target="_blank"
                rel="noopener noreferrer"
              >
                Virtual Car driving game
              </a>
              <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">Unity</div>
                  <div className="technology">Driving Simulator</div>
                  <div className="technology">Oculus Quest 2</div>
                </div>
              </div>
              <p>
                I created this project in Unity engine. I used oculus VR and
                Driving Simulator in this Game.
              </p>
              <div className="buttons">
                <button>
                  <a href="https://www.youtube.com/watch?v=m7l2hK3rKkk&feature=youtu.be">
                    View Game Video
                  </a>
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref3}
          variants={image}
          initial="hidden"
          animate={animation2}
          className="project-3 project"
        >
          <motion.div variants={image} className="project--image--container">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/dev?id=7808063932653629385&hl=en_GB&gl=US"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/repositories.jpg" alt="bitcoon" />
                </a>
              </SwiperSlide>
              {/* <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/dev?id=7808063932653629385&hl=en_GB&gl=US"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/repositories.jpg" alt="bitcoon" />
                </a>
              </SwiperSlide> */}
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="https://play.google.com/store/apps/dev?id=7808063932653629385&hl=en_GB&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Weewoo Mobile Games
              </a>
              <div className="used-technologies">
                <span>Responsibilities:</span>
                <div className="technologies--container">
                  <div className="technology">Ads Integration</div>
                  <div className="technology">Optimization</div>
                  <div className="technology">Change Game UI</div>
                  <div className="technology">New Functionality</div>
                </div>
              </div>
              <p>
                When I worked with the game storm studios My Responsibilities
                was to update Client Weewoo mobile games (Ads Integration,
                Optimization, add new Functionality, Change Game UI, Bug
                Fixing.). I worked on all the games of this account.
              </p>
              {/* <div className="buttons">
                <button>
                  <a href="https://play.google.com/store/apps/dev?id=7808063932653629385&hl=en_GB&gl=US">
                    View Store
                  </a>
                </button>
              </div> */}
            </motion.div>
          </div>
        </motion.div>
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref4}
          variants={image}
          initial="hidden"
          animate={animation2}
          className="project-3 project project--reverse"
        >
          <motion.div variants={image} className="project--image--container">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
              <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/developer?id=Markhor+Game+Studios"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/dashboard.jpg" alt="" />
                </a>
              </SwiperSlide>
              {/* <SwiperSlide>
                <a
                  href="https://play.google.com/store/apps/developer?id=Markhor+Game+Studios"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/dashboard.jpg" alt="" />
                </a>
              </SwiperSlide> */}
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="https://play.google.com/store/apps/developer?id=Markhor+Game+Studios"
                target="_blank"
                rel="noopener noreferrer"
              >
                Personal Publication
              </a>
              {/* <div className="used-technologies">
                <span>Responsibilities:</span>
                <div className="technologies--container">
                  <div className="technology">Ads Integration</div>
                  <div className="technology">Optimization</div>
                  <div className="technology">Change Game UI</div>
                  <div className="technology">new Functionality</div>
                </div>
              </div> */}
              <p>
                I published my own games on google play store to learn about how
                google console and monetization dashboard works and how we make
                money from it.
              </p>
              {/* <div className="buttons">
                <button>
                  <a href="https://play.google.com/store/apps/developer?id=Markhor+Game+Studios ">
                    View Store
                  </a>
                </button>
              </div> */}
            </motion.div>
          </div>
        </motion.div>
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref5}
          variants={image}
          initial="hidden"
          animate={animation2}
          className="project-3 project"
        >
          <motion.div variants={image} className="project--image--container">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
              <SwiperSlide>
                <a
                  href="https://www.upwork.com/freelancers/~012dc4ee7b4d2f3134"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/upwork.jpeg" alt="upwork" />
                </a>
              </SwiperSlide>
              {/* <SwiperSlide>
                <a
                  href="https://www.upwork.com/freelancers/~012dc4ee7b4d2f3134"
                  className="container"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./images/upwork.jpg" alt="upwork" />
                </a>
              </SwiperSlide> */}
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="https://www.upwork.com/freelancers/~012dc4ee7b4d2f3134"
                target="_blank"
                rel="noopener noreferrer"
              >
                Upwork Freelancing Projects
              </a>
              {/* <div className="used-technologies">
                <span>Responsibilities:</span>
                <div className="technologies--container">
                  <div className="technology">Ads Integration</div>
                  <div className="technology">Optimization</div>
                  <div className="technology">Change Game UI</div>
                  <div className="technology">new Functionality</div>
                </div>
              </div> */}
              <p>
                I completed some freelancing projects on Upwork to learn time
                and client tasks management skill.
              </p>
              {/* <div className="buttons">
                <button>
                  <a href="https://play.google.com/store/apps/dev?id=7808063932653629385&hl=en_GB&gl=US">
                    View Store
                  </a>
                </button>
              </div> */}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ProjectsUnity;
