import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

function Achievements() {
  const animation = useAnimation();
  const animation2 = useAnimation();

  const image = {
    visible: {
      opacity: 1,
      x: -0,
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeInOut",
        staggerChildren: 0.4,
      },
    },
    hidden: { opacity: 0, x: -25 },
  };

  const text = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        type: "tween",
        ease: "backInOut",
      },
    },
    hidden: { opacity: 0, y: 25 },
  };

  const [ref, InView] = useInView({ threshold: 0.6 });
  const [ref2, InView2] = useInView({ threshold: 0.6 });

  useEffect(() => {
    if (InView) {
      animation.start("visible");
    }
    if (InView2) {
      animation2.start("visible");
    }
  }, [animation, InView, InView2, animation2]);

  return (
    <motion.div className="projects--container">
      <motion.h1 variants={text}>Certificates and Achievements</motion.h1>
      <motion.div className="projects">
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref}
          variants={image}
          initial="hidden"
          animate={animation}
          className="project-3 project"
        >
          <motion.div variants={image} className="project--image--container">
          <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
            <SwiperSlide>
            <a
              href="#e"
              className="container"
              rel="noopener noreferrer"
            >
              <img src="./images/certificate.jpeg" alt="certificate" />
            </a>
            </SwiperSlide>
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="#e"
                rel="noopener noreferrer"
              >
                Employee of the month 
              </a>
              {/* <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">Java</div>
                  <div className="technology">GUI Design</div>
                  <div className="technology">Derby Database</div>
                </div>
              </div> */}
              <p>
              I got Employee of the month Award from company (Game Storm Studios) due to extraordinary work and effort to project and its feel.
              </p>
            </motion.div>
          </div>
        </motion.div>
        <motion.div
          transition={{
            duration: 1,
            ease: "easeInOut",
            staggerChildren: 0.5,
          }}
          ref={ref2}
          variants={image}
          initial="hidden"
          animate={animation}
          className="project-3 project"
        >
          <motion.div variants={image} className="project--image--container">
          <Swiper
              modules={[Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              autoplay
            >
            <SwiperSlide>
            <a
              href="#e"
              className="container"
              rel="noopener noreferrer"
            >
              <img src="./images/award.jpeg" alt="award" />
            </a>
            </SwiperSlide>
            </Swiper>
          </motion.div>
          <div className="text-container">
            <motion.div variants={text} className="text">
              <a
                className="project-link"
                href="#e"
                rel="noopener noreferrer"
              >
                Rising Star Award 
              </a>
              {/* <div className="used-technologies">
                <span>Technologies:</span>
                <div className="technologies--container">
                  <div className="technology">Java</div>
                  <div className="technology">GUI Design</div>
                  <div className="technology">Derby Database</div>
                </div>
              </div> */}
              <p>
              I got Rising Star Award from company (Game Storm Studios) due to good team work.
              </p>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Achievements;
