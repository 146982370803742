import BoxContent from "./../BoxContent";
import { useState } from "react";
import ContactForm from "./../contactform/contactForm";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

function Profile() {
  const main = {
    visible: {
      opacity: 1,
      y: -0,
    },
    hidden: { opacity: 0, y: -25 },
  };

  const child = {
    visible: {
      opacity: 1,
      x: -0,
      transition: {
        duration: 0.3,
        type: "keyframes",
        ease: "easeInOut",
      },
    },
    hidden: { opacity: 0, x: -25 },
  };

  const [isContactFormVisible, setIsContactFormVisible] = useState(false);
  const [changeArrowPos, setChangeArrowPos] = useState(false);

  return (
    <>
      <motion.div
        initial="hidden"
        id="AboutMe"
        variants={main}
        animate="visible"
        transition={{ duration: 0.3, ease: "easeInOut", staggerChildren: 0.3 }}
        className="profile"
      >
        <div className="background-design">
          <img alt="" src="./images/BoxDesign.svg" />
        </div>
        <BoxContent>
          <motion.div
            variants={main}
            transition={{ staggerChildren: 0.3 }}
            className="profile__text-container"
          >
            <motion.h1 variants={child}>
              Hi, I'm Muhammad Salman
              <br />
              <motion.span className="skill-text">
                <span style={{ color: "rgb(230, 230, 230)" }}>a </span>
                Software Engineer
                {/* <Typewriter
                  options={{
                    strings: ["Software Engineer"],
                    autoStart: true,
                    delay: 100,
                    deleteSpeed: 50,
                    loop: true,
                  }}
                /> */}
              </motion.span>
            </motion.h1>
            {/* <motion.p variants={child}>
              I am passionate about developing complex user interfaces and
              integrating them with backend technologies.
            </motion.p> */}
            <div className="buttons">
              <button
                className="call-to-action-btn"
                onMouseOver={() => setChangeArrowPos(true)}
                onMouseLeave={() => setChangeArrowPos(false)}
                onClick={() => setIsContactFormVisible(true)}
              >
                Get in touch!
                <img
                  className={
                    changeArrowPos
                      ? `arrow--right arrow--right--move`
                      : "arrow--right"
                  }
                  alt="ArrowRight"
                  src="./images/ArrowRight.svg"
                />
              </button>
              <button className="linkedin-btn">
                <a href="https://www.linkedin.com/in/muhammad-salman-3b3708209/" target="_blank" rel="noreferrer">
                  <img src="./images/linkedin.svg" alt="linkedin" />
                  Connect
                </a>
              </button>
              <button className="download-cv-btn">
                <a href="/Muhammad-Salman-Cv.docx" download rel="noreferrer">
                  <img src="./images/download.svg" alt="download" />
                  Download Cv
                </a>
              </button>
            </div>
          </motion.div>
          <div className="profile__image-container"></div>
        </BoxContent>
      </motion.div>
      {isContactFormVisible ? (
        <ContactForm
          isContactFormVisible={isContactFormVisible}
          setIsContactFormVisible={setIsContactFormVisible}
        />
      ) : null}
    </>
  );
}

export default Profile;
